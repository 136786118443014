import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'airbnb-js-shims';

import configureStore, { history } from "./redux/store";
import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: "https://19ee9307050c4fdc8893c823311275ba@sentry.io/1727899" });

const store = configureStore();

ReactDOM.render(<Provider store={store}>
  <ConnectedRouter history={history}>
    <App store={store} />
  </ConnectedRouter>
</Provider>,
  document.getElementById('root'));

serviceWorker.unregister();
