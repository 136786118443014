import React from 'react';
import { Switch, Route } from 'react-router'

import LegalNotice from './components/LegalNotice';
import Home from './components/HomePage';

const Routes = () => (
  <Switch>
    <Route exact path="/legal-notice" render={(props) => <LegalNotice {...props} />} />
    <Route exact path="/:lang?" render={(props) => <Home {...props} />} />
  </Switch>
)

export default Routes;
