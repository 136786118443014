import React from 'react';
import { Row } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default (props) => {
  let { fields } = props.content.items[0];
  let document = documentToReactComponents(fields.content)
  return (
    <Row>
      <div className="text-justify body-text">
        {document}
      </div>
    </Row>
  )
}
